import * as React from 'react'
import Layout from '../components/Layout'
import ProjectList from '../components/ProjectList'
import Seo from '../components/SEO'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Juan Duran | Projects" desc="Portfolio project list for Juan Duran" />
      <main className="relative flex flex-col items-center justify-center mt-10">
        <ProjectList />
      </main>
    </Layout>
  )
}

export default IndexPage
