import React from 'react'
import { Link } from 'gatsby'

const projectsData = [
  {
    title: "Web Refinery",
    cssClass: "web-refinery",
    url: "/project/web-refinery"
  },
  {
    title: "Video Link Scraper",
    cssClass: "video-link-scraper",
    url: "/project/video-link-scraper"
  },
  {
    title: "Sales & Tasks Dashboard",
    cssClass: "sales-tasks-dashboard",
    url: "/project/sales-tasks-dashboard"
  },
  {
    title: "EC Project Page",
    cssClass: "ec-product-page",
    url: "/project/ec-product-page"
  }
]

const projects = projectsData.map((p, idx) => {
  return (
    <Link className="project" to={p.url} key={idx} aria-label={`Project ${p.title}`}>
      <div className={`my-5 mx-5 lg:mx-9 project-background h-60 md:h-80 ${p.cssClass}`}>
        <div className="inner h-60 md:h-80">
          <div className="desc">
            <h3>{p.title}</h3>
          </div>
        </div>
      </div>
    </Link>
  )
})

function ProjectList() {
  return (
    <div className="container">
      <div className="grid grid-cols-1 md:grid-cols-2 mb-5 gap-x-8 gap-y-4">
        { projects }
      </div>
    </div>
  )
}

export default ProjectList